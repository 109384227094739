.vertical-line {
  border-left: 7px solid #D23228;
  transform: rotate(13deg);
}

.edx-logo {
  height: 3rem;
  padding-left: .5rem;

}

.little-hero {
  background-image: url(../images/headerImage.png);
  background-repeat:no-repeat;
  padding: .75rem 0 .75rem .5rem;
}