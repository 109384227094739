.card-image-cap .pgn__card-logo-cap {
  // This prevents stretching of the logo image
  object-fit: contain;
}

.chip-max-width {
  max-width: 18rem;
}

.product-card {
  .chip-max-width {
    max-width: 100%;
  }
}

$breakpoint-medium: 992px;
@media (max-width: $breakpoint-medium) {
  .med-min-height {
    min-height: map-get($spacers, 6);
  }
  .overflow-scroll-medium {
    overflow: scroll;
  }
}

.select-width {
  width: 20rem;
}
